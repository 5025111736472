export default function WritingAndPodcasts() {
  return (
    <>
      <h2>Writing & Podcasting</h2>
      <aside>
        <p><a href="https://medium.com/@damianmakki">Medium</a></p>
        <p><a href="https://narwhals-ae0a791a.simplecast.com/episodes/33-S9tXUAjz">Narwhals Podcast (Episode 33)</a></p>
      </aside>
    </>
  )
}